section.dineroli{
    width: 100%;
    height: 100vh;
    padding: 1rem;
}
section.dineroli .back{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    color: #000;
    font-weight: 6000;
}
.chamber{
    position: relative;
    background-image: url('../../asset/vbg.svg');
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.chamber .video{
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -1;
}
.chamber .video #video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
}
.chamber .content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    text-align: center;
}
.chamber h1{
    color: var(--main-color);
    z-index: 1;
    margin-bottom: 1rem;
}
.chamber article{
    padding-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.chamber article a{
    color: #ffffffee;
    font-size: 1.1rem;
}
.chamber article a:hover{
    text-decoration: underline;
}
@media screen and (max-width: 470px) {
    .chamber h1{
        font-size: 1.5rem;
    }
    .chamber article a{
        font-size: .9rem;
    }
}
section.dineroli section.massive--link{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-template-rows: repeat(2, 49%);
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
}
section.dineroli section.massive--link .link{
    width: 100%;
    height: 100%;
    /* background-color: #ffffffa1; */
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    align-items: cetner;
    justify-content: center;
    flex-direction: column;
    background-color: var(--second-bg-color);
}
section.dineroli section.massive--link .link:hover{
    background-color: rgba(255, 255, 255, 0.199);
}
section.dineroli section.massive--link .link .icon{
    font-size: 2rem;
    color: var(--main-color);
}
section.dineroli section.massive--link .link h3{
    font-size: 2rem;
    color: #fff;
}

@media screen and (max-width: 752px) {
    section.dineroli section.massive--link .link h1{
        font-size: 2rem;
    }
}
@media screen and (max-width: 523px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1.3rem;
    }
}
@media screen and (max-width: 371px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 328px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 298px) {
    section.dineroli section.massive--link .link h1{
        font-size: 1rem;
    }
}
@media screen and (max-width: 557px) {
    section.dineroli section.massive--link .link h3{
        font-size: 1.2rem;
        color: #fff;
    }
}